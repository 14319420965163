import React from "react"
import presentazioneStyles from "./presentazione.module.css"
import { useStaticQuery, graphql } from "gatsby"
import Valori1 from "../images/valori_small.jpg"
import Valori2 from "../images/valori_2_small.jpg"
import Valori3 from "../images/valori_3_small.jpg"
import Gallery from "../components/gallery"
import { useState } from "react"


const Presentazione = React.forwardRef((prop, ref) => {
  const [galleryOpen, setGalleryOpen] = useState(false);

  const data = useStaticQuery(graphql`
    query PresentazioneQuery {
      markdownRemark(fileAbsolutePath: { regex: "/presentazione.md/" }) {
        frontmatter {
          title
          date(formatString: "DD.MM.YYYY")
        }
        htmlAst
      }
    }
  `)

  const text = data.markdownRemark.htmlAst.children[0].children
    .filter(el => el.type === "element")
    .map(el => el.children[0].value)

  return (
    <div ref={ref} className={presentazioneStyles.container} id="presentazione">



      <div className={presentazioneStyles.centerBox}>
        <div className={presentazioneStyles.text} onClick={()=>setGalleryOpen(false)}>{text[0]}</div>
        <div className={presentazioneStyles.signature} onClick={()=>setGalleryOpen(false)}>{text[1]}</div>


            {galleryOpen === false?<>
            <div className={presentazioneStyles.containerImmagini}>
              <div className={presentazioneStyles.containerImmagine} onClick={()=>setGalleryOpen(true)}>            
                <img  className={presentazioneStyles.immagine}
                    alt="valori"
                    src={Valori1}
                  />
              </div>
              <div className={presentazioneStyles.containerImmagine} onClick={()=>setGalleryOpen(true)}>            
                <img  className={presentazioneStyles.immagine}
                    alt="valori"
                    src={Valori2}
                  />
              </div>
              <div className={presentazioneStyles.containerImmagine} onClick={()=>setGalleryOpen(true)}>            
                <img  className={presentazioneStyles.immagine}
                    alt="valori"
                    src={Valori3}
                  />
              </div>
            </div></>:
            <Gallery>
                <img 
                  alt="valori"
                  src={Valori1}
                />

                <img  
                  alt="valori"
                  src={Valori2}
                />

                <img  
                alt="valori"
                src={Valori3}
                />
            </Gallery>}


      </div>
    </div>
  )
})

export default Presentazione


