import React from "react"
import mainStyles from "./main.module.css"
import LogoBSA from "../images/logo.png"

const Main = React.forwardRef((prop, ref) => {
  return (
    <div ref={ref} className={mainStyles.container} id="main">
      <div className={mainStyles.logoContainer}>
        <img
          style={{ maxWidth: "1000px", width: "70vw", height: "auto" }}
          alt="logo"
          src={LogoBSA}
        />
      </div>
    </div>
  )
})

export default Main
