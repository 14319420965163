import React from "react"
import galleryStyles from "./gallery.module.css"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader

const isMobileDevice = () => {
    return (
      typeof window !== "undefined" &&
      (typeof window.orientation !== "undefined" ||
        navigator.userAgent.indexOf("IEMobile") !== -1)
    )
  }

export default props => 
    <div className={galleryStyles.container}>
      <div className={galleryStyles.carouselContainer}>
          <Carousel
                showThumbs={false}
                dynamicHeight
                infiniteLoop
                stopOnHover
                emulateTouch
                autoPlay={false}
                swipeable
                showArrows={isMobileDevice() === false}
                statusFormatter={(current, total) => `${current} di ${total}`}
              >
                  {props.children}


              </Carousel>
        </div>
    </div>
