import React from "react"
import { useState } from "react"

import headerStyles from "./header.module.css"
import MenuIcon from "../images/menu.svg"
import Menu from "./menu"

export default props => {
  const [menuOpen, setMenuOpen] = useState(false)
  const { current } = props

  return (
    <div className={headerStyles.header}>
      <div
        className={
          current === "Main"
            ? headerStyles.menuIconHidden
            : headerStyles.menuIcon
        }
      >
        <div onClick={() => setMenuOpen(!menuOpen)}>
          <img alt="menu" src={MenuIcon} className={headerStyles.menuIconImg} />
        </div>
      </div>

      {menuOpen && (
        <>
          <Menu {...props} callback={()=>setMenuOpen(false)} />

          <div
            onClick={() => setMenuOpen(false)}
            className={headerStyles.blacker}
          ></div>
        </>
      )}
    </div>
  )
}
