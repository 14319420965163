import React from "react"
import contattiStyles from "./contatti.module.css"
import GoogleMapReact from 'google-map-react'
import Phone from "../images/phoneS.svg"
import Mail from "../images/mailS.svg"
import Place from "../images/bsa.png"
import Contatti from "../images/contatti_small.jpg"
const LocationPin = ({ text }) => (
  <div className={contattiStyles.markerContainer}>
              <img alt="place" src={Place} className={contattiStyles.iconBig} />
  </div>
)



const ChiSiamo = React.forwardRef((prop, ref) => {
  const location = {
    address: 'Via Industria 11, 6814 Cadempino',
    lat: 46.034200,
    lng: 8.930620,
  } 

  return (
    <div ref={ref} className={contattiStyles.container} id="contatti">
        <div className={contattiStyles.googleMap}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyAXrrGeTgT0i-uEGfgi2L88GtIglIZySc0' }}
            defaultCenter={location}
            defaultZoom={15}
          >
            <LocationPin
              lat={location.lat}
              lng={location.lng}
              text={location.address}
            />
          </GoogleMapReact>
          </div>
          <div className={contattiStyles.contatti}>
          <div className={contattiStyles.scheda}>
              <div className={contattiStyles.indirizzo1}>Bernasconi SA</div>
              <div className={contattiStyles.indirizzo2}>
                Via Industria 11 <br/>
                6814 Cadempino
              </div>
            
              <div className={contattiStyles.telefono}>
              <img alt="phone" src={Phone} className={contattiStyles.icon} />

              <a className={contattiStyles.link} href="tel:+41 91 960 45 00" target="_blank" rel="noopener noreferrer">
                +41 91 960 45 00
              </a>
            </div>
            <div className={contattiStyles.email}>
              <img alt="mail" src={Mail} className={contattiStyles.icon} />

              <a className={contattiStyles.link} href="mailto:info@bernasconifood.ch" target="_blank" rel="noopener noreferrer">
                info@bernasconifood.ch
              </a>
            </div>     
          </div>
          <div  className={contattiStyles.containerImmagine}>
            <img  className={contattiStyles.immagine}
              alt="contatti"
              src={Contatti}
            />
          </div>
          <div className={contattiStyles.messaggio}>
              Stai per avviare una nuova attività nel settore alimentare?
          </div>
          <div className={contattiStyles.messaggio}>
              Hai già un'attività e vuoi un fornitore serio e affidabile che prende a cuore le tue necessità? 
          </div>
          <div className={contattiStyles.messaggio}>
              Non esitare a contattarci! Siamo felici di poterti assistere nella selezione dei prodotti che più si addicono alla tua attività e di consigliarti nel migliore dei modi.
              <div className={contattiStyles.listino}>
                <a className={contattiStyles.link} href="mailto:info@bernasconifood.ch" target="_blank" rel="noopener noreferrer"> Richiedi il listino</a>
              </div>
          </div>
     
     
          </div>
     
    </div>
  )
})

export default ChiSiamo
