import React from "react"
import chisiamoStyles from "./chisiamo.module.css"
import Phone from "../images/phoneS.svg"
import Mail from "../images/mailS.svg"
import Support from "../images/support.svg"
import People from "../images/people.svg"

const ChiSiamo = React.forwardRef((prop, ref) => {
  return (
    <div ref={ref} className={chisiamoStyles.container} id="chisiamo">
      <div className={chisiamoStyles.reparto}>
        <div className={chisiamoStyles.titoloReparto}>Direzione e finanze</div>

        <div className={chisiamoStyles.scheda}>
          <div>
            <img alt="people" src={People} className={chisiamoStyles.iconBig} />
          </div>
          <div>
            <div className={chisiamoStyles.nomeScheda}>Ivan Bernasconi</div>
            <div className={chisiamoStyles.funzione}>Direttore</div>
            <div className={chisiamoStyles.telefono}>
              <img alt="phone" src={Phone} className={chisiamoStyles.icon} />

              <a href="tel:+41 91 960 45 00" target="_blank" rel="noopener noreferrer">
                +41 91 960 45 00
              </a>
            </div>
            <div className={chisiamoStyles.email}>
              <img alt="mail" src={Mail} className={chisiamoStyles.icon} />

              <a href="mailto:info@bernasconifood.ch" target="_blank" rel="noopener noreferrer">
                info@bernasconifood.ch
              </a>
            </div>
          </div>
        </div>

        <div className={chisiamoStyles.scheda}>
          <div>
            <img alt="people" src={People} className={chisiamoStyles.iconBig} />
          </div>
          <div>
            <div className={chisiamoStyles.nomeScheda}>
              Pamela Schamberger-Bernasconi
            </div>
            <div className={chisiamoStyles.funzione}>
              Assistente di direzione e contabile
            </div>
            <div className={chisiamoStyles.telefono}>
              <img alt="phone" src={Phone} className={chisiamoStyles.icon} />

              <a href="tel:+41 91 960 45 03" target="_blank" rel="noopener noreferrer">
                +41 91 960 45 03
              </a>
            </div>
            <div className={chisiamoStyles.email}>
              <img alt="mail" src={Mail} className={chisiamoStyles.icon} />
              <a href="mailto:contabilita@bernasconifood.ch" target="_blank" rel="noopener noreferrer">
                contabilita@bernasconifood.ch
              </a>
            </div>
          </div>
        </div>

        <div className={chisiamoStyles.scheda}>
          <div>
            <img alt="people" src={People} className={chisiamoStyles.iconBig} />
          </div>
          <div>
            <div className={chisiamoStyles.nomeScheda}>Susi Bernasconi</div>
            <div className={chisiamoStyles.funzione}>Aiuto contabile</div>
            <div className={chisiamoStyles.telefono}>
              <img alt="phone" src={Phone} className={chisiamoStyles.icon} />

              <a href="tel:+41 91 960 45 00" target="_blank" rel="noopener noreferrer">
                +41 91 960 45 00
              </a>
            </div>
            <div className={chisiamoStyles.email}>
              <img alt="mail" src={Mail} className={chisiamoStyles.icon} />
              <a href="mailto:contabilita@bernasconifood.ch" target="_blank" rel="noopener noreferrer">
                contabilita@bernasconifood.ch
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={chisiamoStyles.reparto}>
        <div className={chisiamoStyles.titoloReparto}>Vendita</div>
        <div className={chisiamoStyles.scheda}>
          <div>
            <img alt="phone" src={People} className={chisiamoStyles.iconBig} />
          </div>
          <div>
            <div className={chisiamoStyles.nomeScheda}>Raffaele Scarpazza</div>
            <div className={chisiamoStyles.funzione}>Responsabile vendite</div>
            <div className={chisiamoStyles.telefono}>
              <img alt="phone" src={Phone} className={chisiamoStyles.icon} />

              <a href="tel:+41 79 423 70 12" target="_blank" rel="noopener noreferrer">
                +41 79 423 70 12
              </a>
            </div>
            <div className={chisiamoStyles.email}>
              <img alt="mail" src={Mail} className={chisiamoStyles.icon} />
              <a href="mailto:raffaele@bernasconifood.ch" target="_blank" rel="noopener noreferrer">
                raffaele@bernasconifood.ch
              </a>
            </div>
          </div>
        </div>


        <div className={chisiamoStyles.scheda}>
          <div>
            <img alt="phone" src={Support} className={chisiamoStyles.iconBig} />
          </div>
          <div>
            <div className={chisiamoStyles.nomeScheda}>Deborah Barrocu</div>
            <div className={chisiamoStyles.funzione}>Consulenza telefonica e gestione ordini</div>
            <div className={chisiamoStyles.telefono}>
              <img alt="phone" src={Phone} className={chisiamoStyles.icon} />

              <a href="tel:+41 91 960 45 00" target="_blank" rel="noopener noreferrer">
                +41 91 960 45 00
              </a>
            </div>
            <div className={chisiamoStyles.email}>
              <img alt="mail" src={Mail} className={chisiamoStyles.icon} />
              <a href="mailto:ordini@bernasconifood.ch" target="_blank" rel="noopener noreferrer">
                ordini@bernasconifood.ch
              </a>
            </div>
          </div>          
        </div>

        <div className={chisiamoStyles.scheda} style={{visibility:"hidden"}}>
          <div>
            <img alt="phone" src={Support} className={chisiamoStyles.iconBig} />
          </div>
          <div>
            <div className={chisiamoStyles.nomeScheda}></div>
            <div className={chisiamoStyles.funzione}>Consulenza telefonica e gestione ordini</div>
            <div className={chisiamoStyles.telefono}>
              <img alt="phone" src={Phone} className={chisiamoStyles.icon} />

              <a href="tel:+41 91 960 45 00" target="_blank" rel="noopener noreferrer">
                +41 91 960 45 00
              </a>
            </div>
            <div className={chisiamoStyles.email}>
              <img alt="mail" src={Mail} className={chisiamoStyles.icon} />
              <a href="mailto:ordinia@bernasconifood.ch" target="_blank" rel="noopener noreferrer">
                ordini@bernasconifood.ch
              </a>
            </div>
          </div>
        </div>

      </div>

   
    </div>
  )
})
export default ChiSiamo
