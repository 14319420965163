import React from "react"
import condizioniStyles from "./condizioni.module.css"

const Condizioni = React.forwardRef((prop, ref) => {
  return (
    <div ref={ref} className={condizioniStyles.container} id="chisiamo">
        
        <div className={condizioniStyles.titolo}>
          Condizioni
        </div>
            <div className={condizioniStyles.messaggio}>
              Siamo specializzati nella vendita all'ingrosso di generi alimentari per negozi, macellerie, shop, bar, ristoranti, hotels, catering, feste ed eventi.
              <ul className={condizioniStyles.lista}>
                <li>Non vendiamo a privati</li>
                <li>Non effettuiamo esportazioni all'estero</li>
              </ul>
            </div>
          


   
    </div>
  )
})
export default Condizioni
