import React from "react"
import prodottiStyles from "./prodotti.module.css"

import { useStaticQuery, graphql } from "gatsby"


/*
const isMobileDevice = () => {
  return (
    typeof window !== "undefined" &&
    (typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1)
  )
}
*/

const Prodotti = React.forwardRef((prop, ref) => {

  const data = useStaticQuery(graphql`
  query ProdottiQuery {
    allMarkdownRemark(filter:{fileAbsolutePath: { regex: "/(prodotti)/" }}) {
      edges{
        node{
          frontmatter {
            title
            date(formatString: "DD.MM.YYYY"),
            featuredImage
            order
          }
          htmlAst
        }
      }
  
    }
  }
`)

const prodottiConfig = data.allMarkdownRemark.edges.sort((a,b)=>{
  return a.node.frontmatter.order - b.node.frontmatter.order;
}).map(({node})=>{
  const {featuredImage,title} = node.frontmatter;
  const {children} = node.htmlAst;
  const listaProdottiMD = [].concat(...children.map(c=>{
    const elements = c.children.filter(c=>c.type==="element");
    return elements.map(element=>{
        return {
          file: element.children[0].properties.href,
          nome: element.children[0].children[0].value
        }
    })
  }));
  return {
    img:featuredImage,
    nome:title,
    prodotti:listaProdottiMD
  };  
})


  return (<>
    <div id="prodotti" ref={ref} className={prodottiStyles.content}>
      {prodottiConfig.map((categoria,i)=>

          <div key={`cat${i}`} className={prodottiStyles.boxProdotto}>
              <div className={prodottiStyles.categoriaProdotto}>
                {categoria.nome}
              </div>

            
              <div className={prodottiStyles.prodottoImmagineContainer}>
                <img
                  className={prodottiStyles.prodottoImmagine}
                  src={categoria.img}
                  alt={categoria.nome}
                />
              </div>



              <div className={prodottiStyles.prodottoDescrizione}>              
              <ul className={`${prodottiStyles.testoElenco} ${prodottiStyles.columns}`}>   
                                {categoria.prodotti.map((prodotto,j)=>
                      <li key={`prod${j}`} className={prodottiStyles.elementoLista}><a className={prodottiStyles.link} href={`${prodotto.file}`} rel="noreferrer" target="_blank">{prodotto.nome}</a></li>
                  )}                
                </ul>
              </div>


          </div>
       )}  
      <div className={prodottiStyles.boxProdotto}>
      </div>
    </div>

  <div className={prodottiStyles.downloadInfo}>cliccare su una sottocategoria per scaricare l'elenco dei prodotti</div>
  </>

  )
})

export default Prodotti

