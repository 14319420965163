import React from "react"
import menuStyles from "./menu.module.css"
import clsx from "clsx"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import HomeIcon from "../images/home.svg"

const Menu = React.forwardRef((props, ref) => {
  const { current, direction, callback } = props

  return (
    <div
      onClick={callback}
      ref={ref}
      className={
        direction === "landscape"
          ? menuStyles.menuLandscape
          : menuStyles.menuPortraitContainer
      }
    >
      <AnchorLink to="/#main" title="Main">
        <div
          className={clsx(
            menuStyles.menuItemContainer,
            current === "Main" && menuStyles.selected
          )}
        >
          <div className={menuStyles.icon}>
            <img alt="home" src={HomeIcon} />
          </div>
        </div>
      </AnchorLink>

      <AnchorLink to="/#presentazione" title="Presentazione">
        <div
          className={clsx(
            menuStyles.menuItemContainer,
            current === "Presentazione" && menuStyles.selected
          )}
        >
          <div className={menuStyles.menuItemRow1}>i nostri</div>
          <div className={menuStyles.menuItemRow2}>Valori</div>
        </div>
      </AnchorLink>

      <AnchorLink to="/#servizi" title="Servizi">
        <div
          className={clsx(
            menuStyles.menuItemContainer,
            current === "Servizi" && menuStyles.selected
          )}
        >
          <div className={menuStyles.menuItemRow1}>i nostri</div>
          <div className={menuStyles.menuItemRow2}>Servizi</div>
        </div>
      </AnchorLink>
      <AnchorLink to="/#storia" title="Storia">
        <div
          className={clsx(
            menuStyles.menuItemContainer,
            current === "Ditta" && menuStyles.selected
          )}
        >
          <div className={menuStyles.menuItemRow1}>la nostra</div>
          <div className={menuStyles.menuItemRow2}>Storia</div>
        </div>
      </AnchorLink>
      <AnchorLink to="/#prodotti" title="Prodotti">
        <div
          className={clsx(
            menuStyles.menuItemContainer,
            current === "Prodotti" && menuStyles.selected
          )}
        >
          <div className={menuStyles.menuItemRow1}>i nostri</div>
          <div className={menuStyles.menuItemRow2}>Prodotti</div>
        </div>
      </AnchorLink>
      <AnchorLink to="/#chisiamo" title="Chi siamo">
        <div
          className={clsx(
            menuStyles.menuItemContainer,
            current === "ChiSiamo" && menuStyles.selected
          )}
        >
          <div className={menuStyles.menuItemRow1}></div>
          <div className={menuStyles.menuItemRow2}>Chi siamo</div>
        </div>
      </AnchorLink>
      <AnchorLink to="/#contatti" title="Contattaci">
        <div
          className={clsx(
            menuStyles.menuItemContainer,
            current === "Contatti" && menuStyles.selected
          )}
        >
          <div className={menuStyles.menuItemRow1}></div>
          <div className={menuStyles.menuItemRow2}>Contattaci</div>
        </div>
      </AnchorLink>
    </div>
  )
})

export default Menu
