import React from "react"
import Header from "./header"
import Footer from "./footer"

export default ({ current, children }) => {
 
  return (
    <div>
      <Header current={current} />
      {children}
      <Footer />
    </div>
  )
}
