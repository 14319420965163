import React from "react"
import serviziStyles from "./servizi.module.css"
import Phone from "../images/phone.png"
import Prize from "../images/prize.png"
import Shipping from "../images/shipping.png"
import People from "../images/people.png"

const Servizi = React.forwardRef((prop, ref) => {
  return (
    <div ref={ref} className={serviziStyles.container} id="servizi">
      <div className={serviziStyles.centerBox}>
        <div className={serviziStyles.box}>
          <div className={serviziStyles.icon}>
            <img alt="phone" src={Phone} />
          </div>
          <div className={serviziStyles.smallContainer}>
            <div className={serviziStyles.title}>Servizio clienti</div>
            <div className={serviziStyles.text}>
              Siamo giornalmente in contatto telefonico e/o via email con i
              nostri clienti.
            </div>
          </div>
        </div>
        <div className={serviziStyles.box}>
          <div className={serviziStyles.icon}>
            <img alt="people" src={People} />
          </div>
          <div className={serviziStyles.smallContainer}>
            <div className={serviziStyles.title}>
              Affiancamento e consulenza
            </div>
            <div className={serviziStyles.text}>
              Per noi ha una grandissima importanza poter interagire con i
              nostri clienti e fare del nostro meglio per soddisfare ogni
              esigenza.
            </div>
          </div>
        </div>
        <div className={serviziStyles.box}>
          <div className={serviziStyles.icon}>
            <img alt="quality" src={Prize} />
          </div>
          <div className={serviziStyles.smallContainer}>
            <div className={serviziStyles.title}>Qualità</div>
            <div className={serviziStyles.text}>
              Un accento particolare viene riservato alle specialità regionali
              oltre che a prodotti DOP e IGP.
            </div>
          </div>
        </div>
        <div className={serviziStyles.box}>
          <div className={serviziStyles.icon}>
            <img alt="quality" src={Shipping} />
          </div>
          <div className={serviziStyles.smallContainer}>
            <div className={serviziStyles.title}>Consegne</div>
            <div className={serviziStyles.text}>
              Consegne capillari in tutto il Ticino e settimanali nel resto della Svizzera.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
export default Servizi
