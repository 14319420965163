import React from "react"
import dittaStyles from "./ditta.module.css"
import { useStaticQuery, graphql } from "gatsby"
import Storia1 from "../images/storia_small.jpg"
import Storia2 from "../images/storia_2_small.jpg"
import Storia3 from "../images/storia_3_small.jpg"
import Gallery from "../components/gallery"
import { useState } from "react"

const Ditta = React.forwardRef((prop, ref) => {
  const [galleryOpen, setGalleryOpen] = useState(false);
  const data = useStaticQuery(graphql`
    query DittaQuery {
      markdownRemark(fileAbsolutePath: { regex: "/ditta.md/" }) {
        frontmatter {
          title
          date(formatString: "DD.MM.YYYY")
        }
        htmlAst
      }
    }
  `)

  const text = data.markdownRemark.htmlAst.children[0].children
    .filter(el => el.type === "element")
    .map(el => el.children[0].value)

  const title = data.markdownRemark.frontmatter.title

  return (
    <div ref={ref} className={dittaStyles.container} id="storia">
        <div className={dittaStyles.title}>
        <div>{title}</div>
      </div>

      <div className={dittaStyles.centerBox}>
        <div onClick={()=>setGalleryOpen(false)}>{text[0]}</div>
        {galleryOpen === false?<><div className={dittaStyles.containerImmagini}>
          <div className={dittaStyles.containerImmagine} onClick={(e)=>{setGalleryOpen(true)}}>            
            <img  className={dittaStyles.immagine}
                alt="storia"
                src={Storia1}
              />
          </div>

          <div className={dittaStyles.containerImmagine} onClick={()=>setGalleryOpen(true)}>            
            <img  className={dittaStyles.immagine}
                alt="storia"
                src={Storia2}
              />
          </div>

          <div className={dittaStyles.containerImmagine} onClick={()=>setGalleryOpen(true)}>            
            <img  className={dittaStyles.immagine}
                alt="storia"
                src={Storia3}
              />
          </div>
        </div>
        </>:
          <Gallery>
              <img 
                alt="storia"
                src={Storia1}
              />
              <img
                alt="storia"
                src={Storia2}
              />
              <img 
                alt="storia"
                src={Storia3}
              />
          </Gallery>
        
        }



      </div>

      <div className={dittaStyles.centerBoxB} onClick={()=>setGalleryOpen(false)}>
        <div>{text[1]}</div>
      </div>

    </div>
  )
})

export default Ditta
